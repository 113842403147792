import React, { Component } from 'react';
import { Card, Button, Modal, Col, Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport} from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import Dropdown from 'react-bootstrap/Dropdown';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Cookies from 'js-cookie';

import "./index.css";
import { Link } from 'react-router-dom';
class ListVoiceCampaign extends Component {
	constructor(props) {
		super(props);
		this.state = {
			campaignlist: [],
			selectedCampaignId: 0,
			isButtonDisabled: [],
			confirmStop: false,
			confirmPause: false,
			confirmResume: false,
			confirmRetry: false,
			loading: false,
			showEditChannelModal: false,
			channels : 0,
			campId : 0,
			sizePerPage: 10,
			selectedRows: []
		}
		//<Route path={`${urlPrefix}/campaign-summary`} ><ListCampaign app={props.app} /></Route>
	}
	componentDidMount() {
		this.getCampaigns();
		const savedSizePerPage = Cookies.get('superAdmVoiceCampaignListsizePerPage');
		if (savedSizePerPage) {
			this.setState({ sizePerPage: Number(savedSizePerPage) });
		}
	}

	getCampaigns = () => {
		const userId = this.props.app.userId;
		Fetcher.get(this.props.app.apiPath + '/api/obd/superadmin/campaign/list')
			.then(res => {
				this.setState({
					campaignlist: res,
					confirmStop: false,
					confirmPause: false,
					confirmResume: false,
					confirmRetry: false
				})
			})
			.catch(err => { console.log('Error in fetching Campaigns', err) });
	}

	refreshPage = () => {
		this.setState({ loading: true });
		//window.location.reload();

		setTimeout(() => {
			window.location.reload(false);
		}, 500);
	}

	downloadReport = (index, campaignId, type) => {
		const data = {
			"campaignId": campaignId,
			"reprtType": type
		};
		return Fetcher.post(this.props.app.apiPath + '/api/obd/report/download', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				alert(res.message);
				//document.getElementById('report-' + index).disabled = false;
				//document.getElementById('report-' + index).innerHTML = "Download";
				window.open(this.props.app.reportPath + '/reports/' + campaignId + '.zip', 'Download');
			})
			.catch(err => { console.log('Error in Downloading Report', err) });
	}

	bulkGenerateReport = () => {
		
             console.log("select ids : "+ this.state.selectedRows);

			 const idsToFind = this.state.selectedRows;

			 const filteredRecords = this.state.campaignlist.filter(record => idsToFind.includes(record.campaignId));

			 const totalCallSum = filteredRecords.reduce((sum, record) => sum + record.numbersUploaded, 0);

			 console.log("Total Calls : "+totalCallSum);

			 if(totalCallSum>1000000){

				Notify.notify('Total Numbers Limit cannot exceed 10 Lakhs.');
				this.setState({ reloading: true });

		       setTimeout(() => {
			       window.location.reload(false);
		       }, 2500);
			   return;
			 }

			 const data = {
			     "campaignId":0,
                 "reportType":"bulk",
                 "campaignIds":this.state.selectedRows.toString()
		     };

             console.log("JSON : "+JSON.stringify(data));

			 return Fetcher.post(this.props.app.apiPath+ '/api/obd/report/generate', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.setState({ reloading: true });

		       setTimeout(() => {
			       window.location.reload(false);
		       }, 2500);
			})
			.catch(err => { console.log('Error in Generating Report', err) });


	}

	downloadPending = (index, campaignId) => {
		const data = {
			"campaignId": campaignId,
			"reportType": 'pending_data'
		};
		return Fetcher.post(this.props.app.apiPath + '/api/obd/report/pending', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				//let csvRows = res.join('\n');
				let csvRows = res.map(item => item.substring(0, 10)).join('\n');
				// Create a Blob and initiate the download
				const blob = new Blob([csvRows], { type: 'text/csv' });
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = 'pending_data_'+campaignId+'.csv';
				link.click();
			})
		.catch(err => { 
			Notify.notify('Something went wrong');
			console.log('Error in Downloading Report', err);
		});
	}

	generateReport = (index, campaignId, reportType) => {
		const data = {
			"campaignId": campaignId,
			"reportType": reportType
		};
		return Fetcher.post(this.props.app.apiPath + '/api/obd/report/generate', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {

				alert(res.message);
				this.getCampaigns();
				
				//document.getElementById("greport-" + index).disabled = true;
				//document.getElementById('greport-' + index).innerHTML = "Preparing";
				
			})
			.catch(err => { console.log('Error in Generating Report', err) });
	}

	confirmStop = (status, campaignId) => {
		this.setState({
			confirmStop: status,
			selectedCampaignId: campaignId
		});
	}

	confirmPause = (status, campaignId) => {
		this.setState({
			confirmPause: status,
			selectedCampaignId: campaignId
		});
	}

	confirmResume = (status, campaignId) => {
		this.setState({
			confirmResume: status,
			selectedCampaignId: campaignId
		});
	}

	confirmRetry = (status, campaignId) => {
		this.setState({
			confirmRetry: status,
			selectedCampaignId: campaignId
		});
	}

	stopCampaign = () => {
		const campaignId = this.state.selectedCampaignId;
		const data = {
			campaignId: campaignId
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/campaign/stop', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
			})
			.catch(err => {
				console.log('Error in Stopping', err);
			});
	}

	pauseCampaign = () => {
		const campaignId = this.state.selectedCampaignId;
		const data = {
			campaignId: campaignId
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/campaign/pause', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
			})
			.catch(err => {
				console.log('Error in Pause', err);
			});
	}
	
	resumeCampaign = () => {
		const campaignId = this.state.selectedCampaignId;
		const data = {
			campaignId: campaignId,
			userId: this.props.app.userId
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/campaign/resume', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
			})
			.catch(err => {
				console.log('Error in Resume', err);
			});
	}

	retryCampaign = () => {
		const userId = this.props.app.userId;
		const campaignId = this.state.selectedCampaignId;
		const data = {
			campaignId: campaignId,
			userId: userId
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/campaign/retry', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
			})
			.catch(err => {
				console.log('Error in Retry', err);
			});
	}

	setMessageShow = (status) => {
		this.setState({
			showEditChannelModal: false,
		});
	}

	editChannels = (event) => {
		event.preventDefault();
		// const data = {
		// }
		return Fetcher.post(this.props.app.apiPath + '/api/obd/edit/channels/'+this.state.campId+'/'+this.state.channels, { headers: { "Content-type": "application/json" }, method: 'POST' })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Edit Channels Failed');
				} else {
					Notify.notify(res.message);
				}
				this.refreshPage();
			})
			.catch(err => {
				console.log('Error in Edit Channesl', err)
				Notify.notify('Error in Edit Channels');
			});
	}

	confirmEditChannels = (status, campaignId) => {
		this.setState({
			showEditChannelModal: status,
			campId: campaignId
		});
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	handleSizePerPageChange = (newSizePerPage) => {
		this.setState({ sizePerPage: newSizePerPage });
		Cookies.set('superAdmVoiceCampaignListsizePerPage', newSizePerPage);
	}

	handleSelectAll = (isSelect) => {
         const { campaignlist } = this.state;
         if (isSelect) {
              this.setState({ selectedRows: this.campaignlist.map(p => p.campaignId) });
        } else {
            this.setState({ selectedRows: [] });
        }
    };

	handleSelectRow = (row, isSelect) => {
        this.setState(prevState => {
               const selectedRows = [...prevState.selectedRows];
               if (isSelect) {
                            selectedRows.push(row.campaignId);
               } else {
                        const index = selectedRows.indexOf(row.campaignId);
                        if (index > -1) {
                                    selectedRows.splice(index, 1);
                        }
                }
        return { selectedRows };
       });
    };

	isSelectedAll = () => {
       const { campaignlist, selectedRows } = this.state;
       return selectedRows.length === campaignlist.length;
    };

	render() {

		const { campaignlist,selectedRows } = this.state;
		const campaigns = this.state.campaignlist;
		const columns = [
			{
			     dataField: 'select',
                 text: 'Select',
                 headerRenderer: (columnProps, colIndex, expandColumnProps) => (
                <input
                      type="checkbox"
                      checked={this.isSelectedAll()}
                    onChange={(e) => this.handleSelectAll(e.target.checked)}
                />
                ),
                formatter: (cell, row) => (
                  <input type="checkbox" checked={selectedRows.includes(row.campaignId)}
                    onChange={(e) => this.handleSelectRow(row, e.target.checked)} />
                ),
                headerStyle: { width: '50px'},
            },
			{
				dataField: 'id',
				text: 'Serial No.',
				sort: false,
				hidden: true,
				csvExport: false
			},
			{
				dataField: 'campaignId',
				text: 'Campaign Id',
				sort: false,
				hidden: false,
				csvExport: true
			},
			{
				dataField: 'campaignName',
				text: 'Campaign Name',
				sort: true,
				searchable: true,
				csvExport: false
			},
			{
				dataField: 'userId',
				text: 'User Id',
				sort: false,
				hidden: false,
				csvExport: true
			},
			{
				dataField: 'username',
				text: 'User Name',
				sort: true,
				searchable: true,
				csvExport: false
			},
			
			{
				dataField: 'name',
				text: 'Campaign Name',
				sort: true,
				searchable: true,
				hidden: true,
				csvExport: true
			}, {
				dataField: 'campaignstatus',
				text: 'Campaign Status',
				sort: true,
				searchable: true
			},
			{
				dataField: 'channels',
				text: 'Channels',
				sort: true,
				searchable: true
			}, {
				dataField: 'templateId',
				text: 'Campaign Type',
				sort: true,
				searchable: true
			},{
				dataField: 'scheduleTime',
				text: 'Start Time',
				sort: true,
				searchable: false
			},{
				dataField: 'endTime',
				text: 'End Time',
				sort: true,
				searchable: false
			}, {
				dataField: 'numbersUploaded',
				text: 'Total Numbers',
				sort: false,
				searchable: false
			}, {
				dataField: 'numbersProcessed',
				text: 'Calls Dialed',
				sort: false,
				searchable: false
			}, {
				dataField: 'callsSubmitted',
				text: 'Pending Calls',
				sort: false,
				searchable: false,
				csvExport: false
			},{
				dataField: 'callsSubmittedCsv',
				text: 'Pending Calls',
				sort: false,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'callsConnected',
				text: 'Connected Calls',
				sort: false,
				searchable: false,
				csvExport: false
			},{
				dataField: 'callsConnectedCsv',
				text: 'Connected Calls',
				sort: false,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'pulses',
				text: 'Total Pulses',
				sort: true,
				searchable: false
			},{
				dataField: 'dndCount',
				text: 'DnD Count',
				sort: true,
				searchable: false
			},{
				dataField: 'dtmfCount',
				text: 'Dtmf Count',
				sort: true,
				searchable: false,
				csvExport: false
			},{
				dataField: 'dtmf1Count',
				text: 'Dtmf1 Count',
				sort: true,
				searchable: false,
				csvExport: false
			},{
				dataField: 'dtmf2Count',
				text: 'Dtmf2 Count',
				sort: true,
				searchable: false,
				csvExport: false
			},{
				dataField: 'dtmfCountCsv',
				text: 'Dtmf Count',
				sort: true,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'dtmf1CountCsv',
				text: 'Dtmf1 Count',
				sort: true,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'dtmf2CountCsv',
				text: 'Dtmf2 Count',
				sort: true,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'smsCount',
				text: 'SMS Count',
				sort: true,
				searchable: false
			},{
				dataField: 'smsCountCsv',
				text: 'SMS Count',
				sort: true,
				searchable: false,
				hidden: true,
				csvExport: true
			},{
				dataField: 'retryCount',
				text: 'Retry Count',
				sort: true,
				searchable: false
			},{
				dataField: 'retries',
				text: 'Is Retry',
				sort: true,
				searchable: false,
				hidden: true
			},{
				dataField: 'retries',
				text: 'No. Of retries',
				sort: true,
				searchable: false
			},{
				dataField: 'fullReportStatus',
				text: 'Action',
				sort: false,
				searchable: false,
				csvExport: false
			},];

		const products = Array.isArray(campaigns) ? campaigns.map((campaign, index) => {
			let template = '';

			switch (campaign.templateId) {
				case 0:
					template = 'Simple IVR';
					break;
				case 1:
					template = 'DTMF';
					break;
				case 2:
					template = 'Call Patch';
					break;
				case 3:
					template = 'Custom IVR';
					break;
				case 4:
					template = 'Multi IVR';
					break;
				case 7:
					template = 'TTS';
					break;
				default:
					template = '';
					break;
			}
			let status = '';

			switch (campaign.campaignStatus) {
				case 0:
					status = 'Ready';
					break;
				case 21:
					status = 'Processing';
					break;
				case 1:
					status = 'Running';
					break;
				case 2:
					status = 'Complete';
					break;
				case 3:
					status = 'Low Balance';
					break;
				case 4:
					status = 'Error';
					break;
				case 5:
					status = 'Error';
					break;
				case 6:
					status = 'Cancelling';
					break;
				case 7:
					status = 'Cancelled';
					break;
				case 8:
					status = 'Pausing';
					break;
				case 9:
					status = 'Paused';
					break;
				case 10:
					status = 'Resuming';
					break;
				case 22:
					status = 'Waiting For Channels';
						break;
				default:
					status = '';
					break;
			}
			
			let action = [];
			let retry = [];
			//let 
			action.push(<Dropdown id="dd">
				<Dropdown.Toggle className='btn-sm'>Take Action</Dropdown.Toggle>
					<Dropdown.Menu>
						{/* <Dropdown.Item key={`clone-campaign-${index}`} href={`${this.props.app.urlPrefix}/clone-campaign/${campaign.campaignId}`}>Clone Campaign</Dropdown.Item> */}
						{(campaign.campaignStatus === 1) && <Dropdown.Item key={`ps-campaign-${index}`} onClick={() => this.confirmPause(true, campaign.campaignId)} href="#">Pause Campaign</Dropdown.Item>}
						{(campaign.campaignStatus === 3 || campaign.campaignStatus === 9 || campaign.campaignStatus === 22) && <Dropdown.Item key={`rs-campaign-${index}`} onClick={() => this.confirmResume(true, campaign.campaignId)} href="#">Resume Campaign</Dropdown.Item>}
						{(campaign.campaignStatus === 1) && <Dropdown.Item key={`st-campaign-${index}`} onClick={() => this.confirmStop(true, campaign.campaignId)} href="#">Stop Campaign</Dropdown.Item>}
						{(campaign.campaignStatus === 9) && <Dropdown.Item key={`ps-campaign-${index}`} onClick={() => this.confirmEditChannels(true, campaign.campaignId)} href="#">Edit Channels</Dropdown.Item>}
						{/* {(campaign.fullReportStatus === '2' || campaign.fullReportStatus === undefined) && <Dropdown.Item id={`greport-${index}`} key={`greport-${index}`} onClick={() => this.generateReport(index, campaign.campaignId, 'full')} href="#">Generate Full Report</Dropdown.Item>} */}
					</Dropdown.Menu>
				  </Dropdown>);
				// if(campaign.campaignStatus === 2 && campaign.isRetry === 0)
				// {
				// 	retry.push(<Link data-tip="Retry" key={`${index}-retry`} className="btn btn-sm btn-primary" onClick={(e) => { this.confirmRetry(true, campaign.campaignId) }} href="#">Retry</Link>);
				// }
				let callsSubmitted=0;
				let dtmfDownload=[];
				dtmfDownload.push(campaign.dtmfCount);
				dtmfDownload.push(' ');
				if(campaign.templateId === 1 || campaign.templateId === 2 || campaign.templateId === 3 || campaign.templateId === 4)
				{
					if(campaign.dtmfReportStatus === '2' || campaign.dtmfReportStatus === undefined)
					{
						dtmfDownload.push( <a data-tip="" key={`${index}-dtmf`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'dtmf' ) }} href="#"><i className="fa fa-download"></i></a>);
					}
				}
				let dtmf1Download=[];
				dtmf1Download.push(campaign.dtmf1Count);
				dtmf1Download.push(' ');
				if(campaign.templateId === 3 || campaign.templateId === 4)
				{
					if(campaign.dtmfReportStatus === '2' || campaign.dtmfReportStatus === undefined)
					{
						dtmf1Download.push( <a data-tip="" key={`${index}-dtmf1`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'dtmf1' ) }} href="#"><i className="fa fa-download"></i></a>);
					}
				}
				let dtmf2Download=[];
				dtmf2Download.push(campaign.dtmf2Count);
				dtmf2Download.push(' ');
				if(campaign.templateId === 3 || campaign.templateId === 4)
				{
					if(campaign.dtmfReportStatus === '2' || campaign.dtmfReportStatus === undefined)
					{
						dtmf2Download.push( <a data-tip="" key={`${index}-dtmf2`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'dtmf2' ) }} href="#"><i className="fa fa-download"></i></a>);
					}
				}

				let ccDownload=[];
				ccDownload.push(campaign.callsConnected);
				ccDownload.push(' ');
				if(campaign.callsConnected > 0)
				{
					if(campaign.ccReportStatus === '2' || campaign.ccReportStatus === undefined)
					{
						ccDownload.push( <a data-tip="" key={`${index}`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'connected_calls' ) }} href="#"><i className="fa fa-download"></i></a>);
					}
				}
				let ccPending=[];
				ccPending.push(campaign.numbersUploaded - campaign.dndCount - campaign.numbersProcessed);
				ccDownload.push(' ');
				ccPending.push( <a data-tip="" key={`${index}-pending`} className="download-report" onClick={( e ) => { this.downloadPending( true, campaign.campaignId ) }} href="#"><i className="fa fa-download"></i></a>);
				
				let smsDownload = [];
				smsDownload.push(campaign.smsCount);
				smsDownload.push(' ');
				if(campaign.smsCount > 0){
					if(campaign.smsStatus == 2 || campaign.smsStatus == undefined){
						smsDownload.push( <a data-tip="" key={`${index}-sms`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'sms' ) }} href="#"><i className="fa fa-download"></i></a>);
					}
				}

			return {
				id: index + 1,
				campaignId: campaign.campaignId,
				name: campaign.campaignName,
				userId: campaign.userId,
				username: campaign.username,
				campaignName: (campaign.templateId === 0 || campaign.templateId === 1 || campaign.templateId === 2)?<Link key={`${index}-cm`} to={`${this.props.app.urlPrefix}/voice-campaign-info/${campaign.campaignId}`}>{campaign.campaignName}</Link>:campaign.campaignName,
				campaignstatus: status,
				channels: campaign.channels,
				templateId: template,
				scheduleTime: campaign.scheduleTime,
				endTime: campaign.endTime,
				numbersUploaded: campaign.numbersUploaded,
				numbersProcessed: campaign.numbersProcessed,
				callsSubmitted: (campaign.campaignStatus === 7 || campaign.campaignStatus === 9)?ccPending:campaign.numbersUploaded - campaign.dndCount - campaign.numbersProcessed,
				callsSubmittedCsv: campaign.numbersUploaded - campaign.dndCount - campaign.numbersProcessed,
				callsConnected: (campaign.campaignStatus === 2)?ccDownload:campaign.callsConnected,
				callsConnectedCsv: campaign.callsConnected,
				pulses: campaign.pulses,
				dndCount: campaign.dndCount,
				dtmfCount: (campaign.campaignStatus === 2)?dtmfDownload:campaign.dtmfCount,
				dtmf1Count: (campaign.campaignStatus === 2)?dtmf1Download:campaign.dtmf1Count,
				dtmf2Count: (campaign.campaignStatus === 2)?dtmf2Download:campaign.dtmf2Count,
				dtmfCountCsv: campaign.dtmfCount,
				dtmf1CountCsv: campaign.dtmf1Count,
				dtmf2CountCsv: campaign.dtmf2Count,
				smsCount: (campaign.campaignStatus === 2) ? smsDownload : campaign.smsCount,
				smsCountCsv: campaign.smsCount,
				retryCount: campaign.retryCount,
				retries: (campaign.retries > 0)?'Yes':'No',
				retries: campaign.retries,
				fullReportStatus: action
			};
		}) : []
		const { SearchBar, ClearSearchButton } = Search;
		const { ExportCSVButton } = CSVExport;

		const defaultSorted = [{
			dataField: 'scheduleTime',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: products.length,
			sizePerPage: this.state.sizePerPage,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: this.handleSizePerPageChange
		};
		const filterTime = (date) => {
			const isPastTime = new Date().getTime() > date.getTime();
			return !isPastTime;
			};

		return (
			<Card>
					<Card.Header>Voice Campaign Summary</Card.Header>
					<Card.Body>
						<div className="campaign-list-table-cont">
							{/* <h3>Campaign Summary</h3> */}
							{/*{!isEmpty( this.state.campaignlist ) && <PaginationProvider pagination={paginationFactory( paginationOption )}>*/}
							{<PaginationProvider pagination={paginationFactory(paginationOption)}>
								{({
									paginationProps, paginationTableProps
								}) => (<ToolkitProvider
									keyField="id"
									data={products}
									columns={columns}
									search
									bootstrap4
									exportCSV={{
										fileName: 'campaign_summary.csv'
									}}
								>
									{props => (<div className="">
										Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
										<span className="search-cont"><SearchBar {...props.searchProps} /></span>
										<ClearSearchButton className="btn-primary" {...props.searchProps} />
										<ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton>
										<Button id="refresh" type="button" className="float-right btn btn-default btn-primary ml-2" onClick={() => this.refreshPage()}>Refresh</Button>
										{ (this.state.selectedRows.length >0 ) ? <Button id="refresh" type="button" className="float-right btn btn-default btn-primary ml-2" onClick={() => this.bulkGenerateReport()}>Generate Bulk Report</Button> : <></>}
										<hr />
										<BootstrapTable bordered={true} hover defaultSorted={defaultSorted} wrapperClasses="table-responsive" classes="w-auto campaignlist-table" {...props.baseProps} {...paginationTableProps} noDataIndication={() => (<div>{'No data available'}</div>)} />
										<PaginationListStandalone {...paginationProps} />
									</div>)}
								</ToolkitProvider>)}
							</PaginationProvider>}
						</div>
						<Modal size="md" show={this.state.confirmStop} onHide={() => this.confirmStop(false, '')}>
							<Modal.Header closeButton>
								<Modal.Title id="example-modal-sizes-title-sm">
									Stop Campaign
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								Are you sure you want to stop this campaign?
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={() => this.confirmStop(false, '')} variant="secondary">CANCEL</Button>
								<Button onClick={this.stopCampaign} variant="primary">YES</Button>
							</Modal.Footer>
						</Modal>

						<Modal size="md" show={this.state.confirmPause} onHide={() => this.confirmPause(false, '')}>
							<Modal.Header closeButton>
								<Modal.Title id="example-modal-sizes-title-sm">
									Pause Campaign
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								Are you sure you want to pause this campaign?
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={() => this.confirmPause(false, '')} variant="secondary">CANCEL</Button>
								<Button onClick={this.pauseCampaign} variant="primary">YES</Button>
							</Modal.Footer>
						</Modal>

						<Modal size="md" show={this.state.confirmResume} onHide={() => this.confirmResume(false, '')}>
							<Modal.Header closeButton>
								<Modal.Title id="example-modal-sizes-title-sm">
									Resume Campaign
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								Are you sure you want to resume this campaign?
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={() => this.confirmResume(false, '')} variant="secondary">CANCEL</Button>
								<Button onClick={this.resumeCampaign} variant="primary">YES</Button>
							</Modal.Footer>
						</Modal>

						<Modal size="md" show={this.state.confirmRetry} onHide={() => this.confirmRetry(false, '')}>
							<Modal.Header closeButton>
								<Modal.Title id="example-modal-sizes-title-sm">
									Retry Campaign
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								Are you sure you want to retry this campaign?
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={() => this.confirmRetry(false, '')} variant="secondary">CANCEL</Button>
								<Button onClick={this.retryCampaign} variant="primary">YES</Button>
							</Modal.Footer>
						</Modal>


						<Modal size="md" show={this.state.showEditChannelModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Edit Channels
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.editChannels}>
								<Row>
									<Col sm={6}>
										<Form.Group>
											<Form.Control required name="channels" type="number" placeholder="Channels" onChange={e => this.setvalue(e)} value={this.state.channels} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Edit Channels</Button>
							</Form>
						</Modal.Body>
					</Modal>


						{(this.state.loading === true) && <div className="fetching-data"><img alt="" src="/assets/loading.gif" /></div>}
					</Card.Body>
				</Card>
		);
	}
}


export default ListVoiceCampaign;